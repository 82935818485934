.main-header-conatiner {
  height: 70px;
}
.search_icon {
  position: absolute;
  right: 14vw;
}
.search_icon img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.search-bar-conatiner > input {
  height: 50px;
  width: 70%;
  border-radius: 25px;
  padding: 15px;
}
.search-bar-conatiner > input::placeholder {
  font-size: 18px;
  font-weight: 500;
}

.search-bar-conatiner {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  padding: 0;
}

.header-fixed {
  position: sticky;
  top: 0;
  z-index: 99 !important;
}
