:root {
  --primary-color: #eaffea;
  --black-color: rgb(0 0 0);
  --green-color: #011c05;
  --white-color: #fff;
  --light-green-color: #e5f7e6c6;
}

@import './Header.css';
@import './login.css';
@import './sidebar.css';
@import './dashboard.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

.bg-green {
  background-color: var(--green-color) !important;
}
.btn-green {
  background-color: var(--green-color) !important;
  color: white;
}
.text-green {
  color: #058b19 !important;
}

.table > :not(caption) > * > * {
  padding: 1rem;

  color: var(
    --bs-table-color-state,
    var(--bs-table-color-type, var(--bs-table-color))
  );
  /* background-color: var(--bs-table-bg); */
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px
    var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));

  background-color: transparent !important;
}
.bg-light-green {
  background-color: var(--light-green-color) !important;
}

.border-2px {
  border: 2px solid var(--green-color) !important;
}
input:focus {
  border: none !important;
}

.modal-content {
  border-radius: 25px !important;
}
.modal-content .modal-body {
  overflow: unset !important;
}
.tr-value:hover {
  background-color: #9be59fc6;
}

.dropdown input {
  border: 2px solid var(--green-color);
  width: 100%;
  border-radius: 6px;
  padding: 5px 10px;
  height: 40px;
}
.dropdown label {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 6px;
}
.dropdown-content {
  border: 2px solid var(--green-color);
  width: 100%;
  border-radius: 6px;
  padding: 6px;

  background: #fff !important;
}
.modal-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -65%) !important;

  max-width: 90%;
  max-height: 90%;
}
.modal-footer {
  border-top: none;
}
.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content span {
  color: black;
  padding: 4px 6px;
  text-decoration: none;
  display: block;
  font-weight: 500;
}

.dropdown-content .hover-span:hover {
  background-color: #b3eba1 !important;
  border-radius: 7px;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

/* .dropdown:hover .dropdown-content {
  display: block;
} */
.active-dropdown {
  display: 'block' !important;
}
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.UploadScript_main_wrap h1 {
  font-size: 45px;
  text-align: center;
  color: #011c05;
}
.bordered-button {
  border: 2px solid black;
}

@media screen and (max-width: 991px) {
  .modal-dialog {
    margin-top: 100px;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.custom-scrollbar {
  overflow: auto;
  height: 300px;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.light-green-bg {
  background-color: var(--light-green-color);
}

.rc-tree {
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.rc-tree-treenode {
  padding: 4px 8px;
}

.rc-tree-treenode span {
  color: #007bff;
  cursor: pointer;
}

.rc-tree-treenode-selected {
  background-color: #f0f0f0;
  border-radius: 4px;
}

.rc-tree-treenode:hover {
  background-color: #e6e6e6;
}

.tree-class span {
  color: #007bff;
  text-decoration: underline;
}

.category-tree ul {
  list-style-type: none;
  padding-left: 20px;
  position: relative;
}

.category-tree ul ul {
  margin-left: 20px;
  padding-left: 10px;
  border-left: 1px solid #000;
}

.category-tree li {
  margin: 8px 0;
  padding-left: 1em;
  position: relative;
}

.category-tree li::before {
  content: '';
  position: absolute;
  top: 10px;
  left: -9px;
  width: 17px;
  height: 1px;
  background-color: #000;
}

.category-tree li::after {
  content: '';
  position: absolute;
  top: 0px;
  left: -10px;
  width: 1px;
  height: 121%;
  background-color: #000;
}

.category-tree li:last-child::after {
  height: 10px;
}

/* Basic styling for the button */
.tooltip-btn {
  position: relative;
  /* background-color: #007BFF;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px; */
}

/* Tooltip styling */
.tooltip-text {
  visibility: hidden;
  background-color: #011c05;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  top: 125%; /* Position above the button */
  right: 0;
  /* margin-left: -60px; */
  opacity: 0;
  transition: opacity 0.3s;
  width: 220px;
  /* height: 100px; */
  padding: 10px 8px;
}

/* Add icon to tooltip using :after */
.tooltip-text::after {
  content: '';
  position: absolute;
  top: -19px; /* Position the arrow above the tooltip */
  right: 8px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #011c05 transparent; /* Triangle pointing down */
}

/* Show tooltip on hover */
.tooltip-btn:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.tooltip_text_row h6 {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  width: 40vw;
  text-align: left;
}
.tooltip_text_row p {
  margin: 0;
  font-size: 12px;
  width: 60vw;
  text-align: left;
}
.arrow_down img {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
  top: 12px;
}

/* Existing CSS */

/* Add styles for input error */
.input-error {
  border: 1px solid red !important;
}

/* Error message styling */
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  animation: vibrate 0.3s ease-in-out;
}

/* Smooth vibration effect */
@keyframes vibrate {
  0%,
  100% {
    transform: translateX(0);
  }
  20%,
  60% {
    transform: translateX(-2px);
  }
  40%,
  80% {
    transform: translateX(2px);
  }
}
.form-control,
.form-select {
  margin-bottom: 0;
}

.loader-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* CsvDisplay.css */

.TableData {
  width: 80%;
  margin: 20px auto;
  border-collapse: collapse; /* Ensures borders don't double up */
}

.TableData th,
.TableData td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.TableData th {
  background-color: #f4f4f4;
  width: 150px; /* Set a fixed width for columns */
}

.TableData tr {
  background-color: #fff;
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.TableData tr:hover {
  background-color: #f9f9f9; /* Change color on hover */
}

.css-13cymwt-control {
  border: #000 2px solid !important;
  margin-bottom: 20px !important;
}

.modal-fullscreen-md-down .modal-content {
  height: 60% !important;
}

.js-plotly-plot .plotly .modebar {
  position: absolute;
  top: -10px !important;
  left: 120px !important;
}
.annotation-text {
  font-size: 15px !important;
}

.active > .page-link,
.page-link.active {
  z-index: 3;
  color: white !important;
  background-color: var(--green-color) !important;
  border-color: var(--green-color) !important;
}
.page-link {
  padding: 3px 7px;
  color: black !important;
}

.filter-count-badge {
  position: absolute;
  top: -5px !important;
  right: -5px;
  background-color: var(--green-color);
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding: 0px 6px;
  border-radius: 50%;
}
