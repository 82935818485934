.container {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;

  /* Background color */
  padding: 0 16px;
}

.form-container {
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 20px 40px;
  background-color: var(--primary-color);
  /* Form background color */
  border-radius: 8px;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.textalign-center {
  text-align: center;
}

.heading {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.paragraph {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
}

.link {
  color: var(--black-color);
  /* Primary link color */
  margin: 10px;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
}

.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.checkbox {
  margin-right: 8px;
}

.button {
  width: 100%;
  padding: 12px;
  background-color: var(--black-color);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
